<template>
  <div class="w-full flex justify-center items-center">
    <div v-if="doctor" class="w-full">
      <tab-layout-router
        :route="'superdoctor/selected'"
        :tabs="[
          { key: 'calendar', label: 'Calendario' },
          { key: 'payments', label: 'Ingresos y Pagos' },
          { key: 'settings', label: 'Configuración' },
          { key: 'profile', label: 'Editar Datos' },
          { key: 'help', label: 'Ayuda' },
        ]"
      />
    </div>
    <div
      v-else
      class="w-full border border-gray-200 mt-8 rounded-md flex flex-col justify-center items-center p-8"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-24 w-24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        ></path>
      </svg>
      <span class="text-gray-700 text-sm font-bold"
        >No tienes un perfil seleccionado</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TabLayoutRouter from '@/components/dashboard/TabLayoutRouter.vue';

export default {
  name: 'Selected',
  components: {
    'tab-layout-router': TabLayoutRouter,
  },
  computed: {
    ...mapGetters({
      doctor: ['admin/user'],
    }),
  },
};
</script>
